import DOMPurify from "dompurify";
import fastdom from "fastdom";
import QRCode from "qrcode";
import "regenerator-runtime/runtime";

import { getDeviceInfo } from "../device-detect/index";
import { removeElement, updateDisplay } from "../dom-helpers/classy";
import queryAll, { query } from "../dom-helpers/query";
import { slideInOrOut } from "../dom-helpers/slideIn";

export default function initPreviewFooter(): void {
  const footerEl = queryAll("[id$='-footer_shorthand_link']");
  if (footerEl) {
    updateHost(footerEl);
  }

  //Remove preview footer feedback functionality for IE11 and Edge Classic.
  if (nodes.footer && isUnsupportedBrowser) {
    removeElement(nodes.closeButton);
    removeElement(nodes.footerContents);
    removeElement(nodes.footerTitles);
    removeElement(nodes.interactivePanels);
    removeElement(nodes.mobileHeaders);
    removeElement(nodes.mobileTitle);
    removeElement(nodes.openButton);
    removeElement(nodes.openMessage);
    updateDisplay(nodes.upper, "block");
    nodes.openMessageContainer.classList.add("PreviewFooter--center-horizontal");
    updateDisplay(nodes.upperInner, "flex");
    nodes.footer.style.position = "static";
    return;
  }

  //Setup preview footer feedback functionality
  if (nodes.footer && !isUnsupportedBrowser) {
    nodes.mobileTitle.forEach((_, index) => {
      mobilePanelToggle(nodes.mobileTitle[index], nodes.footerContents[index], nodes.footerClose[index]);
    });
    itemToggle(nodes.openButton);
    itemToggle(nodes.closeButton);

    createSharePanel();
    createFeedbackForm();

    renderUpdate();
    makeFooterReponsive();
    window.addEventListener("scroll", scrollInFooter, { passive: true });
  }
}

const isMobile = getDeviceInfo().isMobile;
const { isIE, isEdge } = getDeviceInfo();
const isUnsupportedBrowser = isIE || isEdge;
let openFooter = false;
let windowWidth = Math.max(document.body.offsetWidth, window.innerWidth);
let responsiveFooter = windowWidth <= 926 || isMobile;

const nodes = {
  closeButton: queryAll(".PreviewFooter--close-button"),
  footer: query(".PreviewFooter") as HTMLElement,
  footerClose: queryAll(".PreviewFooter--mobile-close"),
  footerContents: queryAll("[data-PreviewFooter--item]"),
  footerOuter: query(".PreviewFooter--outer") as HTMLElement,
  footerTitles: queryAll(".PreviewFooter--col-title"),
  interactivePanels: query(".PreviewFooter--interactive-panels") as HTMLElement,
  mailtoLink: query(".PreviewFooter--mailto") as HTMLAnchorElement,
  mobileHeaders: queryAll(".PreviewFooter--mobile-header"),
  mobileTitle: queryAll(".PreviewFooter--col-title-mobile"),
  openButton: query(".PreviewFooter--open-button") as HTMLElement,
  openMessage: query(".PreviewFooter--message") as HTMLElement,
  openMessageContainer: query(".PreviewFooter--message-container") as HTMLElement,
  upper: query(".PreviewFooter--upper") as HTMLElement,
  upperInner: query(".PreviewFooter--upper-inner") as HTMLElement,
};

export function updateHost(anchor: HTMLElement | HTMLElement[]): void {
  const anchors = Array.isArray(anchor) ? anchor : [anchor];
  return anchors.forEach(link => {
    link.setAttribute("href", link.getAttribute("href").replace("__host__", window.location.hostname));
  });
}

function makeFooterReponsive(): void {
  //Check mobile or desktop on load and add listener for desktop resize
  const eventName = isMobile ? "orientationchange" : "resize";
  window.addEventListener(
    eventName,
    () => {
      fastdom.measure(() => {
        windowWidth = Math.max(document.body.offsetWidth, window.innerWidth);
        responsiveFooter = windowWidth <= 926 || isMobile;
        openFooter = false;
        renderUpdate();
      });
    },
    { passive: true }
  );
}

function scrollInFooter(): void {
  const scrollTop = window.scrollY;
  const docHeight = document.body.offsetHeight;
  const winHeight = window.innerHeight;
  const scrollPercent = scrollTop / (docHeight - winHeight);
  const scrollPercentRounded = Math.round(scrollPercent * 100);

  //set the footer to fixed position if browser is IE11
  if (isUnsupportedBrowser || window["Cypress"]) {
    slideInOrOut(nodes.footer, "in");
    removeEventListener("scroll", scrollInFooter);
    return;
  }

  if (!openFooter && scrollPercentRounded < 80) {
    slideInOrOut(nodes.footer, "out");
  }

  if (!openFooter && scrollPercentRounded >= 80) {
    slideInOrOut(nodes.footer, "in");
  }
}

function itemToggle(item: HTMLElement | HTMLElement[]): void {
  const setOpenState = (): void => {
    openFooter = !openFooter;
    renderUpdate();
  };
  const items = Array.isArray(item) ? item : [item];
  items.forEach(element => {
    element.addEventListener(
      "click",
      () => {
        setOpenState();
      },
      { passive: true }
    );
  });
}

const mobilePanelToggle = (item: HTMLElement, content: HTMLElement, close: HTMLElement): void => {
  item.addEventListener(
    "click",
    () => {
      openFooter = true;
      renderUpdate(content);
    },
    { passive: true }
  );
  close.addEventListener(
    "click",
    () => {
      openFooter = false;
      renderUpdate(content);
    },
    { passive: true }
  );
};

function scrollToFooter(): void {
  nodes.footer.style.position = "relative";
  fastdom.measure(() => {
    const docHeight = document.body.offsetHeight;
    fastdom.mutate(() => {
      window.scrollTo({
        top: docHeight,
        behavior: "smooth",
      });
    });
  });
}

function renderUpdate(content: HTMLElement | HTMLElement[] = nodes.footerContents[0]): void {
  switch (true) {
    //footer open and not on mobile
    case openFooter && !responsiveFooter:
      {
        //hide
        updateDisplay(nodes.openButton, "none");
        updateDisplay(nodes.mobileTitle, "none");
        updateDisplay(nodes.mobileHeaders, "none");
        if (windowWidth > 926 && windowWidth < 1100) {
          updateDisplay(nodes.openMessageContainer, "none");
        }
        //show
        updateDisplay(nodes.footerContents, "block");
        updateDisplay(nodes.openMessage, "block");
        updateDisplay(nodes.closeButton, "block");
        updateDisplay(nodes.footerTitles, "block");
        updateDisplay(nodes.upperInner, "flex");
        updateDisplay(nodes.interactivePanels, "flex");
        //other
        nodes.openMessageContainer.classList.remove("PreviewFooter--center-horizontal");
        nodes.upper.style.padding = "1em 0";
        scrollToFooter();
      }
      break;
    //footer closed and not on mobile
    case !openFooter && !responsiveFooter:
      {
        //hide
        updateDisplay(nodes.mobileTitle, "none");
        updateDisplay(nodes.mobileHeaders, "none");
        updateDisplay(nodes.closeButton, "none");
        updateDisplay(nodes.openMessage, "none");
        updateDisplay(nodes.footerContents, "none");
        updateDisplay(nodes.interactivePanels, "none");
        //show
        updateDisplay(nodes.openMessageContainer, "block");
        updateDisplay(nodes.openButton, "flex");
        updateDisplay(nodes.upper, "block");
        updateDisplay(nodes.upperInner, "flex");
        //other
        nodes.openMessageContainer.classList.add("PreviewFooter--center-horizontal");
        nodes.footer.style.position = "sticky";
        nodes.upper.style.padding = "0.5em 0";
      }
      break;
    //footer open and on mobile
    case openFooter && responsiveFooter:
      {
        //hide
        updateDisplay(nodes.mobileTitle, "none");
        updateDisplay(nodes.closeButton, "none");
        updateDisplay(nodes.footerTitles, "none");
        updateDisplay(nodes.upperInner, "none");
        updateDisplay(nodes.footerContents, "none");
        //show
        updateDisplay(content, "block");
        updateDisplay(nodes.mobileHeaders, "flex");
        updateDisplay(nodes.interactivePanels, "flex");
        //other
        nodes.upper.style.padding = "1em 0";
        scrollToFooter();
      }
      break;
    //footer closed and on mobile
    case !openFooter && responsiveFooter:
      {
        //hide
        updateDisplay(nodes.openMessage, "none");
        updateDisplay(nodes.openButton, "none");
        updateDisplay(nodes.closeButton, "none");
        updateDisplay(nodes.footerContents, "none");
        updateDisplay(nodes.interactivePanels, "none");
        //show
        updateDisplay(nodes.upper, "block");
        updateDisplay(nodes.upperInner, "flex");
        updateDisplay(nodes.mobileTitle, "flex");
        updateDisplay(nodes.mobileHeaders, "flex");
        updateDisplay(nodes.openMessageContainer, "block");
        //other
        nodes.footer.style.position = "sticky";
        nodes.upper.style.padding = "0.5em 0";
        nodes.openMessageContainer.classList.add("PreviewFooter--center-horizontal");
      }
      break;
  }
}

function createFeedbackForm(): void {
  const feedbackForm = query("#PreviewFooter--form");
  if (!feedbackForm) {
    return;
  }
  const feedbackButton = query("#PreviewFooter--feedbackButton") as HTMLElement;
  feedbackForm.addEventListener("submit", handleFeedbackForm);
  const formInput = feedbackForm.querySelectorAll("input, textarea");
  formInput.forEach(input => {
    input.addEventListener("focus", () => {
      feedbackButton.classList.add("share-btn-active");
    });
  });
  formInput.forEach(input => {
    input.addEventListener("blur", () => {
      feedbackButton.classList.remove("share-btn-active");
    });
  });
}

const formValidations = {
  feedback: function (value: string) {
    if (value.length >= 4) {
      return;
    }
    return "feedback";
  },
  name: function (value: string) {
    if (value.match(/\b([A-Z\u{00C0}\-\u{00FF}][-,a-z. ']+[ ]*)+/imsu)) {
      return;
    }
    return "name";
  },
  email: function (value: string) {
    if (
      value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      return;
    }
    return "email";
  },
};

const formState = {
  success: function (form) {
    form.reset();
    const successMessage = query("#feedback-success") as HTMLElement;
    updateDisplay(form, "none");
    updateDisplay(successMessage, "block");
    setTimeout(() => {
      updateDisplay(successMessage, "none");
      updateDisplay(form, "block");
    }, 4000);
  },
  error: function (form, input) {
    function setError(errorItem: string): void {
      errorMessage = query(`${errorItem}`) as HTMLElement;
      updateDisplay(errorMessage, "block");
      setTimeout(() => {
        updateDisplay(errorMessage, "none");
      }, 4000);
    }

    if (input.error === "feedback") {
      setError("#feedback-error");
      return;
    }
    if (input.error === "name") {
      setError("#name-error");
      return;
    }
    if (input.error === "email") {
      setError("#email-error");
      return;
    }
    let errorMessage = query("#generic-error") as HTMLElement;
    updateDisplay(form, "none");
    errorMessage.innerText = input;
    updateDisplay(errorMessage, "block");
    setTimeout(() => {
      updateDisplay(errorMessage, "none");
      updateDisplay(form, "block");
    }, 4000);
    console.error(input);
  },
};

const handleFeedbackForm = (event: Event): void => {
  event.preventDefault();
  const storyId = nodes.footer.getAttribute("data-story");
  const form = event.target as HTMLFormElement;
  const formData = new FormData(form);
  const formDataObject = {};
  formData.forEach((value: string, key) => {
    formDataObject[key] = DOMPurify.sanitize(value);
  });
  const formErrors = Object.keys(formValidations).reduce((valid, key) => {
    const error = formValidations[key](formDataObject[key]);
    if (error) {
      return {
        ...valid,
        error,
      };
    }
    return valid;
  }, {});
  if (Object.keys(formErrors).length > 0) {
    formState.error(form, formErrors);
  }
  if (Object.keys(formErrors).length === 0) {
    sendFeedback(formDataObject, storyId).then(() => {
      formState.success(form);
    });
  }
};

function sendFeedback(data: unknown, storyId: string): Promise<void> {
  return fetch(`/${storyId}/feedback`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  }).then(response => {
    if (response.ok) {
      return;
    }
    throw new Error("Something went wrong, please try again.");
  });
}

type mailToData = {
  mailtoEmail: string;
  previewURL: Location | string;
  title: string;
};

function updateMailto({ mailtoEmail, previewURL, title }: mailToData, element: HTMLElement = nodes.mailtoLink): void {
  element.setAttribute(
    "href",
    `mailto:${mailtoEmail}?subject=${encodeURIComponent("Shorthand story for review - ")}${title}&body=${encodeURIComponent(
      "Hello, here's a link to an unpublished (draft) copy of a Shorthand story for the purposes of review:"
    )}%0D%0A%0D%0A${previewURL}%0D%0A%0D%0A${encodeURIComponent(
      "This link is for review only, and should not be promoted or shared. The story at this address may still be actively edited, extensively changed, or removed."
    )}`
  );
  element.setAttribute("target", "_blank");
}

function createSharePanel(): void {
  const form = query(".mailtoForm") as HTMLFormElement;
  const formData = new FormData(form);
  const data = { mailtoEmail: "", previewURL: document.location, title: "" };
  formData.forEach((value, key) => {
    data[key] = value;
  });
  if (data.title === "") {
    data.title = "A Shorthand Story";
  }
  updateMailto(data);
  const emailInput = query("#mailtoEmail") as HTMLInputElement;
  const mailtoButton = query("#mailtoButton") as HTMLButtonElement;

  emailInput.addEventListener("focus", () => {
    mailtoButton.classList.add("share-btn-active");
  });

  emailInput.addEventListener("blur", function (event) {
    data.mailtoEmail = (event.target as HTMLInputElement).value;
    updateMailto(data);
    mailtoButton.classList.remove("share-btn-active");
  });

  const canvasEle = document.querySelector("#qr-code");

  if (canvasEle) {
    QRCode.toCanvas(canvasEle, data.previewURL.toString(), {
      margin: 0,
      width: 116,
      color: {
        dark: "#fff",
        light: "#353535",
      },
    });
  }
}
